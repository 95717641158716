import React from 'react';
import cl from './footer.module.css'

const Footer = () => {
    return (
        <div className={cl.footer}>
            <center>
                <p>©MatveySuvorov, @Mot0511</p>
            </center>
        </div>
    );
};

export default Footer;